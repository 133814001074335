/*
 * 업무구분: 고객
 * 화 면 명: MSPCM011D
 * 화면설명: 신용카드 인증
 * 접근권한: 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
 * 작 성 일: 2022.06.09
 * 작 성 자: 이태훈
 * 2차 작성자: sh.park2022
 */
<template>
  <ur-page-container :show-title="true" type="subpage" class="msp" title="신용카드 인증" @on-header-left-click="fn_Back">
    <ur-box-container direction="column" alignV="start">
      <div class="ns-insideWidthAuto full">
        <mo-validate-watcher ref="vWatcher">
            <ur-box-container direction="column" alignV="start" componentid="ur_box_container_000" class="ns-verify">
              
              <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="verify-box pd5">
                <!-- 고객명 -->
                <mo-text-field ref="custNmRef" :rules="validateRuleName" v-model="custCardVO.custNm" :disabled="disabled_custNm"
                  maxlength="30" @keyup.enter="fn_CheckCustNm('enter')"  @blur="fn_CheckCustNm" underline placeholder="고객명 입력 후 조회를 선택해주세요" />
                <mo-button class="ns-btn-round" @click="fn_CheckCustNm('enter')" :disabled="disabled_custNm">조회하기</mo-button>

                <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check">
                  <!-- 인증결과-->
                  <mo-checkbox v-if="custCardVO.isExistCustYN === 'Y'" class="is-checked" readonly>기존고객입니다.</mo-checkbox>
                  <mo-checkbox v-if="custCardVO.isExistCustYN === 'N'" class="is-checked" readonly>신규고객입니다.</mo-checkbox>
                  <!-- 외국인 여부-->
                  <!-- 고객조회 완료 시, 외국인 선택 못하도록 처리 : 2022-08-12 -->
                  <!-- <mo-checkbox v-model="fn_IsForeigner" :disabled="(infoPrcusCnsntTypCd==='19')? true: (disabled_custNm === true)? true: false">외국인</mo-checkbox> -->
                  <mo-checkbox v-model="fn_IsForeigner" :disabled="disabled_exist_foreign">외국인</mo-checkbox>
                </ur-box-container>
              </ur-box-container>

              <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column">
                <div class="verify-birth">
                  <!-- 생년월일-->
                  <mo-text-field ref="knbFrnoRef1" v-if="!isForeigner" :rules="validateRuleKnbFrno" type="number" mask="######" v-model="fn_KnbFrno" 
                      underline class="birth-input1" placeholder="생년월일" :disabled="disabled_exist"/>

                  <!-- 외국인등록번호-->
                  <mo-text-field ref="knbFrnoRef2" v-else :rules="validateRuleKnbFrno" type="number" mask="######" v-model="fn_KnbFrno" underline class="birth-input1" 
                      placeholder="외국인등록번호" :disabled="disabled_exist"/>

                  <span>-</span>
                  <mo-text-field ref="knbBknoRef" :rules="validateRuleKnbBkno" v-model="fn_Gender" underline type="number" mask="#" class="birth-input2" placeholder="0" 
                    :disabled="disabled_exist"/>
                  <em>******</em>
                </div>
              </ur-box-container>

              <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column">
                <div class="verify-phone">
                  <!-- 전화번호 앞자리 -->
                  <msp-bottom-select :items="phoneItems" v-model="fn_CelnoFrno" underline :disabled="disabled_inComp"/>
                  <!-- 전화번호 뒷자리 -->
                  <mo-text-field ref="celnoRef" v-model="fn_CelnoBkno" :rules="validateRuleCelno" :disabled="disabled_inComp" underline placeholder="00000000" :readonly="disabledObj.celnoBknoDisabled"
                     type="number" mask="########"/>
                </div>
              </ur-box-container>

              <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="verify-btn ns001">
                <mo-button class="ns-btn-round" @click="fn_BtnP1OnClick" :disabled="$bizUtil.isEmpty(custCardVO.isExistCustYN)">인증하기</mo-button>
                <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="ns-check">
                  <mo-checkbox class="is-checked" readonly v-if="authConfirm">인증되었습니다.</mo-checkbox>
                </ur-box-container>
                <span class="txt gray">
                  &#8251; 주의사항
                  <span class="txt-ment">1. 법인카드, 가족카드, 선불카드는 본인 확인이 불가합니다.</span>
                  <span class="txt-ment">2. 휴대폰에 착신전환, 수신거부, 로밍 등의 설정이 되어 있는 경우 서비스 이용에 불가능할 수 있습니다.</span>
                  <span class="txt-ment">3. 카드사에 등록된 고객님의 휴대폰 번호가 변경되었거나 잘못 등록된 경우 변경 후 이용하시기 바랍니다.</span>
                </span>
              </ur-box-container>
              <div class="ns-height70"></div>

              <ur-box-container v-if="ShowBottomBtn" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
                <div class="relative-div">
                  <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="!authConfirm" @click="fn_CcrdAthntCmplt">다음</mo-button>
                </div>
              </ur-box-container>

            </ur-box-container>
        </mo-validate-watcher>
      </div>  
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import Msg from '@/systems/webkit/msg/msg'
  import cmConstants from '@/config/constants/cmConstants.js'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue' /* dropdown bottom-sheet */
  
  import MSPCM005P from '@/ui/cm/MSPCM005P' // 고객선택

  export default {
    /***********************************************************************************
    * Vue 파일 속성 정의 영역	                                                       *
    ***********************************************************************************/
    // 현재 화면명
    name: 'MSPCM011D',
    // 현재 화면 ID
    screenId: 'MSPCM011D',
    components: {
      MspBottomSelect,
      MSPCM005P
    },
    props: {
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        validateRuleName: [
          v => !!v || '필수 입력항목입니다',
          v => this.custNmResult || this.custNmErrorMsg
        ],
        validateRuleKnbBkno: [
          v => !!v || '필수 입력항목입니다',
          v => this.fn_CheckCKnbGender() || this.knbErrorMsg
        ],
        validateRuleKnbFrno: [
          v => !!v || '필수 입력항목입니다',
          v => v.length > 5 || '생년월일을 입력하세요.'
        ],
        validateRuleCelno: [
          v => !!v || '필수 입력항목입니다',
          v => v.length > 7 || '휴대폰번호를 입력하세요.',
          v => this.chkCelnoResult || this.celnoErrorMsg
        ],
        title: '신용카드 인증', // 화면명
        popup005: {}, // MSPCM005P 고객선택 팝업
        authConfirm: false,
        test: (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'stage2'),
        // 주고객 정보 VO
        custCardVO: {
          isExistCustYN:'', //신규 여부
          custNm: '', // 이름
          chnlCustId: '', // 채널고객ID
          chnlCstgrId: '', // 채널그룹ID
          knb: '', // 전체주민번호
          knbBackup: '', // 주민번호 백업 - TSSSP103M의 이전 버튼 선택 시, 마지막 등록된 주민번호값을 화면에 display해야 함.
          knbFrno: '', // 주민번호 앞자리
          knbBkno: '', // 주민번호 뒷자리
          knbBkFstNo: '', // 주민번호 뒷자리 첫자리 (성별구분코드)
          knbBknoCipher: '', // 암호화된 주민번호
          knbBknoCipherBackup: '', // 주민번호 뒷자리 백업(암호화 값이 들어올 경우에만 임시 보관장소로 쓰임)
          knbScCd: 'ZPER01', // 식별번호구분코드
          celno: '', // 휴대전화 전체
          celnoFrno: {}, // 휴대전화 앞자리
          celnoBkno: '', // 휴대전화 뒷자리
        },
        disabledObj: {
          chkForeignerDisabled: false,
          mcnstDisabled: false,
          mktDisabled: false,
          knbFrnoDisabled: false,
          knbBknoDisabled: false,
          addrTypeDisabled: false,
          zipBtnDisabled: false,
          entryDisabled: false,
          celnoFrnoDisabled: false,
          celnoBknoDisabled: false,
          emailDisabled: false,
          emailBknoDisabled: false,
          jobDisabled: false,
          nextBtnDisabled: false // 다음버튼 비활성화 여부
        },
        chkCelnoResult: true, // 핸드폰번호 유효성
        celnoErrorMsg: '', // 핸드폰번호 에러 메시지
        chkKnbResult: true, // 주민번호 유효성 검증 결과
        knbErrorMsg: '', // 주민번호 유효성 체크 후 에러 메시지
        custNmResult: true, // 이름 유효성 체크 결과
        custNmErrorMsg: '', // 이름 유효성 결과 에러 메시지
        phoneItems: [],
        lv_Cds_ZA_ELTRN_MAL_DM_CD: [], // 이메일도메인 코드정보
        isForeigner: false, // 외국인 체크 여부
        chkRrn: false, // 미확보 체크여부
        disabled_inComp: true, // 휴대폰번호 입력 컴포넌트 - 초기진입 시 : 비활성화
        disabled_exist: true, // 생년월일, 외국인 입력 컴포넌트 - 초기진입 시 : 비활성화
        disabled_exist_foreign: false,
        disabled_custNm: false, // 고객명, 조회 입력 컴포넌트 - 초기진입 시 : 비활성화
        agreeTypeMcnst: false, // 필수컨설팅 동의 여부
        mktChkList: [], //마컨 정보(광고성 수신동의)['P', 'S', 'M', 'E']
        mktCnsntAvlPrd : '', //개인(신용)정보 보유이용기간 선택
        infoPrcusCnsntTypCd: '', // 정보활용동의 유형코드(17: 필수컨설팅, 19: 마케팅동의, 18: 둘다)
        segmentType1: '', //마케팅 (거래, 미거래)

        routeCustNm: '',
        ShowBottomBtn: true // 키패드 노출시, 버튼 숨김
      };
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    /** computed 함수 정의 영역 */
    computed: {
      fn_KnbFrno: { // 주민번호 앞자리 변경시 호출 - 전체주민번호변수(custCardVO.knb)에 변경 값 처리
        get: function () {
          return this.custCardVO.knbFrno
        },
        set: function (data) {
          this.custCardVO.knbFrno = data

          if (data.length === 6) {
            this.fn_NextComp ('knbBknoRef')
          }
        }
      },
      fn_Gender: { // 주민번호 뒷자리 성별정보 입력 시 처리
        get: function () {
          return this.custCardVO.knbBkFstNo
        },
        set: function (data) {
          this.custCardVO.knbBkFstNo = data

          if (data.length === 1) {
            this.fn_NextComp ('celnoRef')
          }
        }
      },
      fn_IsForeigner: { // 외국인 체크박스
        get: function () {
          return this.isForeigner
        },
        set: function (data) {
          this.isForeigner = data

          if (data) {
            this.custCardVO.knbScCd = 'ZPER02' // 외국인
            this.$refs.vWatcher.clearValidation()
          } else {
            this.custCardVO.knbScCd = 'ZPER01' // 내국인
          }
        }
      },
      fn_CelnoFrno: { // 핸드폰번호 앞자리 변경시 호출 - 전체핸드폰번호변수(custCardVO.celno)에 변경 값 처리
        get: function () {
          return this.custCardVO.celnoFrno
        },
        set: function (data) {
          if (this.custCardVO.celnoFrno !== null && this.custCardVO.celnoFrno !== data) {
            this.custCardVO.isCelnoChanged = true
          }

          this.custCardVO.celnoFrno = data
          this.custCardVO.celno = data + this.custCardVO.celnoBkno

          if (this.custCardVO.isCelnoChanged) { // 신규이거나 기존 고객의 핸드폰번호 수정시에만 체크
            this.fn_CheckCelno()
          }
        }
      },
      fn_CelnoBkno: { // 핸드폰번호 뒷자리 변경시 호출 - 전체핸드폰번호변수(custCardVO.celno)에 변경 값 처리
        get: function () {
          return this.custCardVO.celnoBkno
        },
        set: function (data) {
          if (this.custCardVO.celnoBkno !== '' && this.custCardVO.celnoBkno.replace('-', '').replace('-', '') !== data) {
            this.custCardVO.isCelnoChanged = true
          }
          this.custCardVO.celnoBkno = data
          this.custCardVO.celno = this.custCardVO.celnoFrno + data
        }
      }

    },
    /** watch 정의 영역 */
    watch: {
    },

    /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
    created() {
      const lv_Vm = this
      
      this.fn_Init()
      
      if(!_.isEmpty(this.$route.params)){
        this.agreeTypeMcnst = this.$route.params.agreeTypeMcnst //필컨 정보 여부
        this.mktChkList = this.$route.params.mktChkList //마컨 정보(광고성 수신동의)
        this.routeCustNm = !this.$bizUtil.isEmpty(this.$route.params.custNm) ? this.$route.params.custNm : ''
        this.custCardVO.custNm = this.routeCustNm
        this.segmentType1 = this.$route.params.segmentType1 
        this.mktCnsntAvlPrd = this.$route.params.mktCnsntAvlPrd //마컨 정보(개인정보 보유이용기간)
        this.infoPrcusCnsntTypCd =  this.$route.params.infoPrcusCnsntTypCd //필컨만 : 17, 마동만 : 19, 필컨&마동 : 18
      } else {
        let custInfoStore = window.vue.getStore('cmStore').getters.getState.custInfo

        this.infoPrcusCnsntTypCd = custInfoStore.infoPrcusCnsntTypCd
        this.pCustNm = !this.$bizUtil.isEmpty(custInfoStore.custNm) ? custInfoStore.custNm : ''
      }
      // backButton event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_Back)

      // ************ 키패드 이벤트 등록 ************ //
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.KEYBOARD_SHOW, () => {
        // 키패드 노출시 처리
        lv_Vm.ShowBottomBtn = false
      })
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.KEYBOARD_HIDE, () => {
        // 키패드 숨김시 처리
        lv_Vm.ShowBottomBtn = true
      })

    },
    mounted() {
      this.$bizUtil.insSrnLog('MSPCM011D') //필수
    },
    beforeDestroy () {
      // backButton event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_Back)

      // 키패드 이벤트 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.KEYBOARD_SHOW);
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.KEYBOARD_HIDE);
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*********************************************************
       * Function명: fn_Init
       * 설명: 로딩시 정보항목 초기화 및 설정
       * Params: N/A
       * Return: N/A
       *********************************************************/
      fn_Init () {
        const lv_Vm = this
        let lv_Item = {}
        
        // 휴대전화 앞자리 정보 세팅
        // let lv_CelphCD = lv_Vm.getStore('code').getters.getState.get('ZA_CELPH_KNB_CD')
        let defaultValu = {value: '0', text: '선택'}
        let lv_CelphCD = this.$bizUtil.cloneDeep(cmConstants.CELPH_KNB_CD)
        this.phoneItems = [defaultValu, ...lv_CelphCD]

        // 휴대폰번호 앞자리 초기값 셋팅
        this.custCardVO.celnoFrno = '010'

        this.$nextTick(() => {
          lv_Vm.$refs['custNmRef'].focus()
        })
      },
      /******************************************************************************
      * Function명 : fn_CheckCustNm
      * 설명       : 신규일 때 고객명 입력 후 focus out 될 때 호출
      ******************************************************************************/
      fn_CheckCustNm (param) { // 고객명 체크
        this.$refs.vWatcher.clearValidation()
        if (this.$bizUtil.isEmpty(this.custCardVO.custNm)) {
          this.$refs['custNmRef'].validate()
          return
        }
        let lv_CustNmResult = this.custCardVO.custNm.trim()
        let regexp_eng = '[a-zA-Z]' // 영문(대소)
        
        this.custCardVO.custNm = lv_CustNmResult

        if (this.$bizUtil.isEmpty(lv_CustNmResult)) {
          this.custNmResult = false // 이름 유효성 체크 결과
          this.custNmErrorMsg = '필수 입력항목입니다'
          this.$refs.vWatcher.validate()
          return
        }

        // 한글 10글자, 영문은 30글자 제한
        if (!this.$bizUtil.isEmpty(lv_CustNmResult)) {
          if (lv_CustNmResult.search(regexp_eng) > -1) { // 영문이 있으면
            lv_CustNmResult = lv_CustNmResult.substr(0, 30)
            this.custCardVO.custNm = lv_CustNmResult
          } else {
            lv_CustNmResult = lv_CustNmResult.substr(0, 10)
            this.custCardVO.custNm = lv_CustNmResult
          }
        }

        // 마케팅동의만 한 외국인인 경우 필수컨설팅 안내 문자 추가
        if (lv_CustNmResult.search(regexp_eng) > -1) {
          if (this.$bizUtil.isCustNameValidation(lv_CustNmResult, 'ZPER02')) {
            if (this.infoPrcusCnsntTypCd==='19') {
              this.custNmResult = false // 이름 유효성 체크 결과
              this.custNmErrorMsg = '외국인 고객님은 마케팅동의 단독처리가 불가합니다.'
              this.$refs.vWatcher.validate()
              return
            } else {
              if (!this.isForeigner) {
                this.custNmResult = false // 이름 유효성 체크 결과
                this.custNmErrorMsg = '외국인 체크를 해주세요'
                this.$refs.vWatcher.validate()
                return
              }
            }
          }
        }

        if (this.$bizUtil.isCustNameValidation(lv_CustNmResult, this.custCardVO.knbScCd)) {
          this.custNmResult = true // 이름 유효성 체크 결과
          // 기존고객 조회 서비스
          if (param === 'enter') { // 엔터키 입력시에만 고객조회함
            this.fn_SearchCustomerInfo()
          }
        } else {
          this.custNmResult = false // 이름 유효성 체크 결과
          this.custNmErrorMsg = '유효하지 않은 고객명입니다. 정확한 정보를 확인하시기 바랍니다.(숫자,영문, 특수문자 등)'
        }

        //쓰는건지 확인 필요 2022-06-15
        if (this.$commonUtil.isMobile) { // 모바일일 경우만 다음버튼 활성화 되게 처리
          this.fn_IsNextBtnVisible(true)
        }

        this.$refs.vWatcher.validate()

        return this.custNmResult
      },
      /******************************************************************************
      * Function명 : fn_SearchCustomerInfo
      * 설명       : 기존고객 조회 (정보활용 동의 정보 조회 서비스 호출)
      ******************************************************************************/
      fn_SearchCustomerInfo () {
        this.isLoading = true
        let lv_Vm = this
        lv_Vm.custCardVO.isExistCustYN = '' // 기존/신규 여부 초기화

        const validOnlyEnglish = (s) => !/(?=.*[^\w\s])/.test(s)
        lv_Vm.lv_FilterSearchCustNm = lv_Vm.custCardVO.custNm
        if (validOnlyEnglish(lv_Vm.lv_FilterSearchCustNm)) {
          lv_Vm.custCardVO.custNm = lv_Vm.lv_FilterSearchCustNm.toUpperCase()
        }

        let pParams =
                    {
                      cnsltNo: this.getStore('userInfo').getters.getUserInfo.userEno, // 컨설턴트사번
                      custNm: lv_Vm.custCardVO.custNm.trim() // 고객명
                    }

        // GA 매니저 대행 컨설턴트 변경시
        if(this.$cmUtil.fn_GachangeTF()){
          pParams.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
        }
        //매니저가 컨설턴트를 변경했을경우
        this.stndKeyList = []
        if(this.$cmUtil.fn_GachangeTF()){
          this.stndKeyList = [{stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo}]
        }

        let trnstId = 'txTSSCM10S4'

        this.post(lv_Vm, pParams, trnstId, 'S')
          .then(function (response) {
          // 필수 컨설팅 리스트
            if (response.body !== null) {
              if (response.body.cMInfoPrcusCnsntListVO !== null) {
                lv_Vm.items = response.body.cMInfoPrcusCnsntListVO
                if (lv_Vm.items.length === 0) {
                  lv_Vm.getStore('confirm').dispatch('ADD', '신규고객 입니다.\n정보 입력 후 본인인증을 시도해주세요')
                  lv_Vm.disabled_exist = false // 생년월일, 외국인 입력 컴포넌트 활성화
                  lv_Vm.disabled_exist_foreign = false
                  lv_Vm.disabled_inComp = false // 휴대폰번호 입력 컴포넌트 활성화
                  lv_Vm.custCardVO.isExistCustYN = 'N'
                  lv_Vm.custCardVO.chnlCustId = ''
                  lv_Vm.custCardVO.knbFrno = ''
                  lv_Vm.custCardVO.knbBkno = ''
                  lv_Vm.custCardVO.knbBkFstNo = ''
                  lv_Vm.custCardVO.celno = ''
                  lv_Vm.custCardVO.celnoFrno = lv_Vm.phoneItems[1].value
                  lv_Vm.custCardVO.celnoBkno = ''
                } else if (lv_Vm.items.length > 0) {
                  // 고객 조회 팝업창 띄우기
                  lv_Vm.popup005 = lv_Vm.$bottomModal.open(MSPCM005P, {
                    properties: {
                      pCustNm: lv_Vm.custCardVO.custNm // 고객명 파라미터
                    },
                    listeners: {
                      confirmPopup: (pData) => {
                        lv_Vm.$bottomModal.close(lv_Vm.popup005)

                        // 파라미터 세팅
                        lv_Vm.$refs.vWatcher.clearValidation()
                        lv_Vm.fn_ConfirmMSPCM005P(pData)

                        lv_Vm.disabled_sendBtn = false // 카카오 인증 버튼 활성화
                      }
                    }
                  })
                }

              } 
              else {
                lv_Vm.getStore('confirm').dispatch('ADD', '신규고객 입니다.\n정보 입력 후 본인인증을 시도해주세요')
                lv_Vm.disabled_exist = false // 생년월일, 외국인 입력 컴포넌트 활성화
                lv_Vm.disabled_exist_foreign = false
                lv_Vm.disabled_inComp = false // 휴대폰번호 입력 컴포넌트 활성화
                lv_Vm.custCardVO.isExistCustYN = 'N'
                lv_Vm.custCardVO.chnlCustId = ''
                lv_Vm.custCardVO.knbFrno = ''
                lv_Vm.custCardVO.knbBkno = ''
                lv_Vm.custCardVO.knbBkFstNo = ''
                lv_Vm.custCardVO.celno = ''
                lv_Vm.custCardVO.celnoFrno = lv_Vm.phoneItems[1].value
                lv_Vm.custCardVO.celnoBkno = ''
              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }

            lv_Vm.isLoading = false
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      /*********************************************************
       * Function명: fn_IsNextBtnVisible
       * 설명: 다음버튼 보이게 하는 여부 처리 (focus일 경우 false, blur(focus out)일 경우 true)
       * Params: N/A
       * Return: N/A
       *********************************************************/
      fn_IsNextBtnVisible (val, refNm) {
        if (this.$commonUtil.isMobile()) { // 모바일일 경우만 처리 - text input focus로 안드로이드 키보드 올라올 경우 '다음' 버튼 안보이게 처리
          this.isNextBtnVisible = val
          this.focusRefName = refNm
        }
      },
      /******************************************************************************
      * Function명 : fn_CheckCKnbGender
      * 설명       : 주민번호 뒷자리 성별구분코드에 따른 정합성 체크
      ******************************************************************************/
      fn_CheckCKnbGender () {
        let lv_Msg = '유효하지 않은 생년월일 정보입니다. 정확한 정보를 확인하시기 바랍니다.'
        // 주민번호 뒷자리 처리
        let lv_FrontYear = this.$bizUtil.addBirthFrontYear(this.custCardVO.knbFrno).substring(0, 2) // 19, 20 조회

        if (this.custCardVO.knbFrno !== null && this.custCardVO.knbFrno !== '') {
          if (lv_FrontYear === '19') {
            if (this.isForeigner === true) {
              if (this.custCardVO.knbBkFstNo !== '5' && this.custCardVO.knbBkFstNo !== '6') {
                if (this.custCardVO.knbBkFstNo === '1' || this.custCardVO.knbBkFstNo === '2') {
                  this.chkKnbResult = false
                  this.knbErrorMsg = '외국인 체크를 해제하세요'
                } else {
                  this.chkKnbResult = false
                  this.knbErrorMsg = lv_Msg
                }
              } else {
                this.chkKnbResult = true
              }
            } else {
              if (this.custCardVO.knbBkFstNo !== '1' && this.custCardVO.knbBkFstNo !== '2') {
                if (this.custCardVO.knbBkFstNo === '5' || this.custCardVO.knbBkFstNo === '6') {
                  this.chkKnbResult = false
                  this.knbErrorMsg = '외국인 체크를 하세요'
                } else {
                  this.chkKnbResult = false
                  this.knbErrorMsg = lv_Msg
                }
              } else {
                this.chkKnbResult = true
              }
            }
          } else if (lv_FrontYear === '20') {
            if (this.isForeigner === true) {
              if (this.custCardVO.knbBkFstNo !== '7' && this.custCardVO.knbBkFstNo !== '8') {
                if (this.custCardVO.knbBkFstNo === '5' || this.custCardVO.knbBkFstNo === '6') {
                  this.chkKnbResult = false
                  this.knbErrorMsg = '100세 이상의 보험가입은 지점에 연락해주시기 바랍니다.'
                } else {
                  this.chkKnbResult = false
                  this.knbErrorMsg = '외국인 체크를 해제하세요'
                }
              } else {
                this.chkKnbResult = true
              }
            } else {
              if (this.custCardVO.knbBkFstNo !== '3' && this.custCardVO.knbBkFstNo !== '4') {
                if (this.custCardVO.knbBkFstNo === '1' || this.custCardVO.knbBkFstNo === '2') {
                  this.chkKnbResult = false
                  this.knbErrorMsg = '100세 이상의 보험가입은 지점에 연락해주시기 바랍니다.'
                } else {
                  this.chkKnbResult = false
                  this.knbErrorMsg = '외국인 체크 하세요'
                }
              } else {
                this.chkKnbResult = true
              }
            }
          }
        }

        if (this.chkKnbResult) {
          lv_Msg = this.fn_CheckAgreePossible() // 만 14세 이상인지 여부 체크 - 14세 미만인 경우 confirm창의 메시지 내용이 리턴됨.

          if (lv_Msg != '') {
            this.chkKnbResult = false
            this.knbErrorMsg = lv_Msg
          }
        }

        return this.chkKnbResult
      },
      /******************************************************************************
      * Function명 : fn_CheckAgreePossible
      * 설명       : 만 14세 이상인지 여부 확인 후, 동의 여부 선택 가능한지 체크 - 필컨,마동 체크 선택이나 주민번호 입력 시 체크함.
      ******************************************************************************/
      fn_CheckAgreePossible () {
        let lv_Msg = ''
        if (this.infoPrcusCnsntTypCd) { // 필컨, 마동 둘중 하나라도 선택되어 있으면 14세 이상인지 체크
          if (this.custCardVO.knbFrno !== '' && this.custCardVO.knbFrno.length === 6 && this.custCardVO.knbBkFstNo !== '') {
            let lv_CheckAge = this.$bizUtil.compareCurrentAge(this.custCardVO.knbFrno, 14)

            if (lv_CheckAge === false) {
              lv_Msg = '해당 고객은 만 14세 미만으로 단독 동의진행이 불가합니다.'
              // this.getStore('confirm').dispatch('ADD', lv_Msg)
            }
          }
        }
        return lv_Msg
      },
      /******************************************************************************
      * Function명 : fn_BtnP1OnClick
      * 설명       : 신용카드 인증 수행
      ******************************************************************************/
      fn_BtnP1OnClick () {
        if (!this.$refs.vWatcher.isValid()) { // 추가 유효성 검사
          return
        }
        
        let lv_RetVal = this.fn_Validation()
        if (lv_RetVal) {
          let lv_Vm = this
          let phone = this.custCardVO.celno
          let birth = this.$bizUtil.getBirthDateFromKnb(lv_Vm.custCardVO.knbFrno + lv_Vm.custCardVO.knbBkFstNo)
          let sexCd = 'F'
          if (lv_Vm.custCardVO.knbBkFstNo === '1' || lv_Vm.custCardVO.knbBkFstNo === '3' || lv_Vm.custCardVO.knbBkFstNo === '5' || lv_Vm.custCardVO.knbBkFstNo === '7') {
            sexCd = 'M'
          }
          

          console.log('신용카드인증 정보:' + lv_Vm.custCardVO.custNm + ' :: ' +  birth + ' :: ' + sexCd + ' :: ' + phone)
          window.fdpbridge.exec('CardCertPlugin', {
            custNm: lv_Vm.custCardVO.custNm,
            birth: birth,
            sexCd: sexCd,
            phone: phone,
            cpCode: 'RO9900000116'
          }, function (result) {
            if (result.data === 'Y') {
              lv_Vm.disabled_inComp = true // 정상처리인 경우 - 휴대폰 입력 컴포넌트 비활성화
              lv_Vm.disabled_exist = true // 생년월일 입력 컴포넌트 비활성화
              lv_Vm.disabled_exist_foreign = true
              lv_Vm.disabled_custNm = true // 고객명 입력 컴포넌트 비활성화
              lv_Vm.fn_AuthFinish()
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', '신용카드 인증에 실패하였습니다.')
            }
          }, function (result) {
            // 에러콜백
          })
        }
      },
      /******************************************************************************
      * Function명 : fn_Validation
      * 설명       : 인증하기 버튼 클릭 시 유효성 체크
      ******************************************************************************/
      fn_Validation () {
        let lv_RetVal = true

        let lv_Msg = this.fn_CheckAgreePossible() // 만 14세 이상인지 여부 체크 - 14세 미만인 경우 confirm창의 메시지 내용이 리턴됨.

        if (lv_Msg != '') {
          this.getStore('confirm').dispatch('ADD', lv_Msg)
          return false
        }
        
        if (this.custCardVO.isExistCustYN === undefined || this.custCardVO.isExistCustYN === '') {
          this.getStore('confirm').dispatch('ADD', '고객 조회 후 진행가능합니다.')
          lv_RetVal = false
          return lv_RetVal
        } else if (this.$bizUtil.isEmpty(this.custCardVO.celnoFrno)) {
          this.getStore('confirm').dispatch('ADD', '휴대폰번호를 입력하세요.')
          lv_RetVal = false
          return lv_RetVal
        }
        if (this.infoPrcusCnsntTypCd === '19') {
          if (this.custCardVO.knbBkFstNo === '5' || this.custCardVO.knbBkFstNo === '6' || this.custCardVO.knbBkFstNo === '7' || this.custCardVO.knbBkFstNo === '8') {
            this.getStore('confirm').dispatch('ADD', '외국인 고객님은 마케팅동의 단독처리가 불가합니다.')
            lv_RetVal = false
            return lv_RetVal
          }
        } else {
          if (this.custCardVO.knbBkFstNo === '5' || this.custCardVO.knbBkFstNo === '6' || this.custCardVO.knbBkFstNo === '7' || this.custCardVO.knbBkFstNo === '8') {
            if (!this.isForeigner) {
              this.getStore('confirm').dispatch('ADD', '외국인 체크를 확인하시기 바랍니다.')
              lv_RetVal = false
              return lv_RetVal
            }
          } else {
            if (this.isForeigner) {
              this.getStore('confirm').dispatch('ADD', '외국인 체크를 해제하시기 바랍니다.')
              lv_RetVal = false
              return lv_RetVal
            }
          }
        }
        return lv_RetVal
      },
      /******************************************************************************
      * Function명 : fn_CheckCelno
      * 설명       : 핸드폰번호 유효성 체크
      ******************************************************************************/
      fn_CheckCelno () {
        this.chkCelnoResult = true

        let lv_Celno = this.custCardVO.celno.replace('-', '').replace('-', '').replace('-', '').trim()
        if (this.custCardVO.celnoFrno === '0') { // 선택일 경우 무조건 에러 처리
          this.chkCelnoResult = false
          this.celnoErrorMsg = this.$t('cm')['ECMC066'] // '휴대폰 번호를 정확히 입력하여 주세요'
        } else if (this.custCardVO.celnoFrno === '010') { // 010 번호인 경우 전체 자리수는 무조건 11자리
          if (lv_Celno.length < 11) {
            this.chkCelnoResult = false
            this.celnoErrorMsg = this.$t('cm')['ECMC066'] // '휴대폰 번호를 정확히 입력하여 주세요'
          } else if (lv_Celno.length === 11) {
            // 임시 휴대전화 등록 및 FC 번호와 동일한지 여부 체크
            this.fn_SelCelnoCheck()
          }
        } else {
          if (lv_Celno.length < 10) { // 010 외에는 뒷자리가 7자리(xxx-xxxx)로 올수도 있음. 전체 자리수를 10자리를 max로 해서 체크
            this.chkCelnoResult = false
            this.celnoErrorMsg = this.$t('cm')['ECMC066'] // '휴대폰 번호를 정확히 입력하여 주세요'
          } else {
            // 임시 휴대전화 등록 및 FC 번호와 동일한지 여부 체크
            this.fn_SelCelnoCheck()
          }
        }
      },
      /******************************************************************************
      * Function명 : fn_SelCelnoCheck
      * 설명       : 핸드폰번호 유효성 체크(임시 번호, FC와 동일번호 체크)
      ******************************************************************************/
      fn_SelCelnoCheck (paramCelno) {
        let lv_CustCelno = this.custCardVO.celno
        if (paramCelno !== undefined && paramCelno !== null && paramCelno !== '') { // created 시점의 임시번호 호출
          lv_CustCelno = paramCelno
        }
        if (lv_CustCelno === '01000000000' || lv_CustCelno === '0100000000' || lv_CustCelno === '01010000000' || lv_CustCelno === '01020000000' || lv_CustCelno === '01030000000') {
          this.chkCelnoResult = false
          this.celnoErrorMsg = this.$t('cm')['ECMC067'] // '임의번호는 입력할 수 없습니다.(예: 010-xxxx-0000, 010-xxxx-1111 등) 다시 확인 후 입력 바랍니다.'
          return
        }

        if ((paramCelno !== undefined && paramCelno !== null && paramCelno !== '') || (this.custCardVO.isCelnoChanged && this.custCardVO.unAprvCustYn === 'N')) { // 임시번호 호출이나 기존 승인고객의 핸드폰변경이 있는경우 유효성 체크
          this.isLoading = true
          let lv_Vm = this

          // 고객 VO복사
          let lv_CustCardVO = Object.assign({}, lv_Vm.custCardVO)

          if (this.$commonUtil.isMobile()) {
            if (lv_CustCardVO.knbBknoCipher.length < 8) { // 모바일이고, 주민번호 변경 안한 케이스
              if (lv_CustCardVO.knbBknoCipher !== '1000000' && lv_CustCardVO.knbBknoCipher !== '2000000' &&
                lv_CustCardVO.knbBknoCipher !== '3000000' && lv_CustCardVO.knbBknoCipher !== '4000000') { // 주민번호 확보 고객은 knb 제거
                lv_CustCardVO.knb = ''
              }
              lv_CustCardVO.knbBknoCipher = '' // 주민번호 미변경
            }
          }

          lv_CustCardVO.celno = lv_CustCelno

          let pParams = lv_CustCardVO
          let trnstId = 'txTSSCM10S3'

          this.post(lv_Vm, pParams, trnstId, 'S')
            .then(function (response) {
              if (response.body !== null) {
                if (response.body.celnoStatCo === '00') { // 정상일 경우
                  lv_Vm.chkCelnoResult = true
                } else {
                  if (response.body.celnoStatCo === '01') { // 01: 임시등록 번호 (*화면에서 1차로 filtering함. 현재는 사용안함.)
                    lv_Vm.chkCelnoResult = false
                    lv_Vm.celnoErrorMsg = lv_Vm.$t('cm')['ECMC067'] // '임의번호는 입력할 수 없습니다.(예: 010-xxxx-0000, 010-xxxx-1111 등) 다시 확인 후 입력 바랍니다.'
                  } else if (response.body.celnoStatCo === '02') { // 02: FC휴대번호와 동일한 경우
                    lv_Vm.chkCelnoResult = false
                    lv_Vm.celnoErrorMsg = lv_Vm.$t('cm')['ECMC068'] // '컨설턴트 핸드폰번호로 등록된 번호입니다.'
                  }
                }
              } else {
                lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
              }

              lv_Vm.isLoading = false
            })
            .catch(function (error) {
              window.vue.error(error)
            })
        }
      },
      /******************************************************************************
      * Function명 : fn_ConfirmMSPCM005P
      * 설명       : 고객검색 팝업창에서 확인 버튼 클릭 시 호출.
                    - 팝업창 닫고, 팝업에서 넘어온 파라미터로 화면 셋팅
      ******************************************************************************/
      fn_ConfirmMSPCM005P (param) {
        if (param != null) {
          if (param.isExistCustYN === 'Y') {
            this.custCardVO.custNm = param.custNm
            this.custCardVO.isExistCustYN = param.isExistCustYN
            this.custCardVO.chnlCustId = param.chnlCustId
            this.custCardVO.chnlCustScCd = param.chnlCustScCd
            this.custCardVO.chnlCstgrId =  param.chnlCstgrId
            this.custCardVO.knbFrno = param.knb.substring(0, 6)
            this.custCardVO.knbBkFstNo = param.knb.substring(6, 7)

            if (this.custCardVO.knbBkFstNo === '5' || this.custCardVO.knbBkFstNo === '6' || this.custCardVO.knbBkFstNo === '7' || this.custCardVO.knbBkFstNo === '8') { // 외국인인 경우
              this.isForeigner = true
              this.custCardVO.knbScCd = 'ZPER02'
            } else if (this.custCardVO.knbBkFstNo === '1' || this.custCardVO.knbBkFstNo === '2' || this.custCardVO.knbBkFstNo === '3' || this.custCardVO.knbBkFstNo === '4') { // 내국인인 경우
              this.isForeigner = false
              this.custCardVO.knbScCd = 'ZPER01'
            }
            /************************************************************************************
            * ----------- 휴대전화 세팅 start -----------
            ************************************************************************************/
            if (param.celno !== undefined && param.celno.trim() !== '') {
              param.celnoOrg = param.celno.split('-')
              let lv_CelSplit = param.celno.split('-')
              let tmpCelnoFrno = lv_CelSplit[0] // 휴대전화 번호 앞자리

              // 조회한 휴대전화번호 앞자리의 값이 있는지 체크함. 없는 경우 tmpCelnoFrnoObj 빈 객체임.
              let tmpCelnoFrnoObj = this.phoneItems.filter(
                (value) => {
                  if (value.key === tmpCelnoFrno) {
                    return value
                  }
                }
              )

              // 휴대전화 번호 앞자리
              if (tmpCelnoFrnoObj.length > 0) {
                this.custCardVO.celnoFrno = tmpCelnoFrnoObj[0]
              } else {
                this.custCardVO.celnoFrno = tmpCelnoFrno
              }

              let lv_CelnoBkno = param.celno.substring(tmpCelnoFrno.length) // 휴대전화 번호 뒷자리
              this.custCardVO.celnoBkno = lv_CelnoBkno.replace(/\-/g, '') //lv_CelnoBkno.replace('-', '') // 첫번째 dash 제거
            }
            /************************************************************************************
             * ----------- 휴대전화 세팅 end -----------
             ************************************************************************************/
            this.disabled_exist = true // 생년월일, 외국인 입력 컴포넌트 비활성화
            this.disabled_exist_foreign = true
            this.disabled_inComp = false // 휴대폰번호 입력 컴포넌트 활성화
          } else {
            this.disabled_exist = false // 생년월일, 외국인 입력 컴포넌트 활성화
            this.disabled_exist_foreign = false
            this.disabled_inComp = false // 휴대폰번호 입력 컴포넌트 활성화
            this.custCardVO.isExistCustYN = param.isExistCustYN
            this.custCardVO.chnlCustId = ''
            this.custCardVO.chnlCstgrId = ''
            this.custCardVO.knbFrno = ''
            this.custCardVO.knbBkno = ''
            this.custCardVO.knbBkFstNo = ''
            this.custCardVO.celno = ''
            this.custCardVO.celnoFrno = this.phoneItems[1].value
            this.custCardVO.celnoBkno = ''
          }
        }
      },
      /******************************************************************************
      * Function명 : fn_AuthFinish
      * 설명       : 다음버튼 및 인증완료 활성화
      ******************************************************************************/
      fn_AuthFinish () {
        this.authConfirm = true
        this.disabled_exist = true // 생년월일, 외국인 입력 컴포넌트 비활성화
        this.disabled_exist_foreign = true
        this.disabled_inComp = true // 휴대폰번호 입력 컴포넌트 비활성화
        this.fn_CcrdAthntCmplt() // 다음단계로 바로 넘어감
      },
      /******************************************************************************
      * Function명 : fn_CcrdAthntCmplt
      * 설명       : 다음 단계 진행
      ******************************************************************************/
      fn_CcrdAthntCmplt () {
        this.$emit('authCompleted', {infoPrcusCnsntPathCd: '15', aprvNo: '', custCardVO: this.custCardVO})
      },
      /******************************************************************************
       * Function명: fn_Back
       * 설명: 헤더 영역 Back Button 파라미터 넘기기
       * Params: N/A
       * Return: N/A
       ******************************************************************************/
      fn_Back () {
        let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
        window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt-1)
        this.$router.go(-1)
        return
        this.$commonUtil.setLastScreen(this.$options.screenId)
        this.$router.push(
          {
            name: 'MSPCM004M',
            params: {
              name: 'MSPCM020M',
              agreeTypeMcnst: this.agreeTypeMcnst, //필컨 정보 여부
              mktChkList: this.mktChkList, //마컨 정보(광고성 수신동의)
              mktCnsntAvlPrd: this.mktCnsntAvlPrd, //마컨 정보(개인정보 보유이용기간)
              infoPrcusCnsntTypCd:  this.infoPrcusCnsntTypCd, //필컨만 : 17, 마동만 : 19, 필컨&마동 : 18
              custNm: this.routeCustNm
            }
          }
        )
      },
      /******************************************************************************
      * Function명: _Back
      * 설명: 헤더 영역 Back Button 파라미터 넘기기
      *          >>>> 2차 개발하였으나, 사용안함
      * Params: N/A
      * Return: N/A
      ******************************************************************************/
      _Back () {
        return
        let alertMsg = '고객동의를 종료하시겠습니까?<br/> 동의종료 선택시 작성된 고객정보는 저장되지 않으며, 고객동의는 완료되지 않습니다.'
        let preSrnObj = window.vue.getStore('cmStore').getters.getState.preSrnObjCM050M
        let preSrnId = !this.$bizUtil.isEmpty(preSrnObj.srnId) ? preSrnObj.srnId : 'MSPBC002M'

        let alertObj = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            content: alertMsg,
            title_pos_btn: '나가기'
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(alertObj)
              
              window.vue.getStore('cmStore').dispatch('PRE_SRN_OBJ_CM050M', {}) // 진입전 정보 초기화
              this.$router.push({ name: preSrnId, params: preSrnObj.rtnParam })
            },
            onPopupClose: () => {
              this.$bottomModal.close(alertObj)
            }
          }
        })

      },
      fn_NextComp (param) {
        if (param === 'knbFrnoRef1' || param === 'knbFrnoRef2') { // 이름에서 focus out 되었을 때
          if (this.$bizUtil.isEmpty(this.custCardVO.custNm)) {
            return
          }
          this.$refs[param].focus()
        } else if (param === 'knbBknoRef') { // 주민번호 앞자리에서 focus out 되었을 때
          if (this.custCardVO.knbFrno.length < 6) {
            return
          }
          this.$refs[param].focus()
        } else if (param === 'celnoRef') { // 통신사 선택 후
          this.$refs[param].focus()
        }

        // this.$refs[param].focus()
      }
    } //methods end
  };
</script>
<style scoped>
</style>